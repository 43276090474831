import axios from 'axios';

axios.defaults.baseURL = 'https://api.studiohyggehome.com/api';
axios.defaults.withCredentials = false;

export const authAdmin = async (credentials) => {
    return await axios.post("/authenticate", credentials);
}   

export const getMe = async () => {
    return await axios.get('/me', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const getContentList = async (contentType) => {
    return await axios.get('/content?type=' + contentType, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const getContentById = async (contentType, contentId) => {
    return await axios.get('/single-content?type=' + contentType + '&id=' + contentId, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const postNewContent = async (contentData) => {
    return await axios.post('/content/create', contentData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const postNewContentMultipart = async (contentData) => {
    return await axios.post('/content/create', contentData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer'),
            'Content-Type' : 'multipart/form-data'
        }
    })
}

export const updateContent = async (contentData) => {
    return await axios.post('/content/update', contentData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const postUpdateContentMultipart = async (contentData) => {
    return await axios.post('/content/update', contentData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer'),
            'Content-Type' : 'multipart/form-data'
        }
    })
}

export const deleteContentGalleryImage = async (imageId) => {
    return await axios.post('/content/gallery/delete-image?id=' + imageId, {}, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const setMainImageById = async (imageId) => {
    return await axios.post('/content/gallery/set-image-as-main?id=' + imageId, {}, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const deleteContent = async (contentId) => {
    return await axios.post('/content/delete?id=' + contentId, {}, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const getSettings = async () => {
    return await axios.get('/settings', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}

export const postSettings = async (data) => {
    return await axios.post('/settings/update', {data: data}, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('bearer')
        }
    })
}
