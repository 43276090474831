import {createBrowserRouter} from 'react-router-dom';

import App, {loader as AppLoader} from '../App';

export const router = createBrowserRouter(
    [
        {
            element: <App />,
            loader: AppLoader,
            children: [
                {
                    path: '/',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/dashboard.page');
                        return {Component: Component, loader: loader}
                    },
                    index: true 
                },
                {
                    path: '/strony',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/pagesModule/list.page');
                        return {Component: Component, loader: loader}
                    }
                },
                {
                    path: '/strony/dodaj',
                    lazy: async () => {
                        let {Component} = await import ('../pages/pagesModule/create.page');
                        return {Component: Component}
                    }
                },
                {
                    path: '/strony/:id',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/pagesModule/edit.page');
                        return {Component: Component, loader: loader}
                    }
                },

                {
                    path: '/blog',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/blogModule/list.page');
                        return {Component: Component, loader: loader}
                    },
                },
                {
                    path: '/blog/dodaj',
                    lazy: async () => {
                        let {Component} = await import ('../pages/blogModule/create.page');
                        return {Component: Component}
                    }
                },
                {
                    path: '/blog/:id',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/blogModule/edit.page');
                        return {Component: Component, loader: loader}
                    }
                },

                
                {
                    path: '/oferta',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/offerModule/list.page');
                        return {Component: Component, loader: loader}
                    },
                },
                {
                    path: '/oferta/:id',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/offerModule/edit.page');
                        return {Component: Component, loader: loader}
                    },
                },


                {
                    path: '/realizacje',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/portfolioModule/list.page');
                        return {Component: Component, loader: loader}
                    },
                },
                {
                    path: '/realizacje/dodaj',
                    lazy: async () => {
                        let {Component} = await import ('../pages/portfolioModule/create.page');
                        return {Component: Component}
                    }
                },
                {
                    path: '/realizacje/:id',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/portfolioModule/edit.page');
                        return {Component: Component, loader: loader}
                    },
                },
                {
                    path: '/slider',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/sliderModule/list.page');
                        return {Component: Component, loader: loader}
                    },
                },
                {
                    path: '/slider/dodaj',
                    lazy: async () => {
                        let {Component} = await import ('../pages/sliderModule/create.page');
                        return {Component: Component}
                    }
                },
                {
                    path: '/slider/:id',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/sliderModule/edit.page');
                        return {Component: Component, loader: loader}
                    },
                },
                {
                    path: '/ustawienia',
                    lazy: async () => {
                        let {Component, loader} = await import ('../pages/settings.page');
                        return {Component: Component, loader: loader}
                    },
                },



            ]
        },
        {
            path: '/login',
            lazy: async () => {
                let {Component} = await import ('../pages/login.page');
                return {Component: Component}
            }
        }
    ]
)