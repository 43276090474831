import { Container, Nav, Navbar } from "react-bootstrap";

const NavigationPartial = ({me}) => {

    return(
        <Navbar fixed="top" expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/">Hygge&Home CMS</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse  id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="/strony">Strony</Nav.Link>
                        <Nav.Link href="/blog">Blog</Nav.Link>
                        <Nav.Link href="/oferta">Oferta</Nav.Link>
                        <Nav.Link href="/realizacje">Realizacje</Nav.Link>
                        <Nav.Link href="/slider">Slider</Nav.Link>
                        <Nav.Link href="/ustawienia">Ustawienia</Nav.Link>
                        <Navbar.Text className="text-success">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zalogowany/a jako: <a href="/profil"><strong>{me.email}</strong></a>
                        </Navbar.Text>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

}
export default NavigationPartial