import { Outlet, redirect, useLoaderData } from "react-router-dom";
import { Container } from "react-bootstrap";

import NavigationPartial from "./partials/navigation.partial";
import FooterPartial from "./partials/footer.partial";

import { getMe } from "./common/api";

export async function loader (){
    const me = await getMe().then( response => {
        return response.data.user;
    }).catch((error) => {
        return null;
    })

    if(localStorage.getItem('bearer') === null || me === undefined || me === null){
        return redirect('/login');
    }

    return me;
}

const App = () => {
    const data = useLoaderData()
    //console.log(data.user)
    return(
        <div className="cms-wrapper">
            <NavigationPartial me={data} />
            <Container className="mb-4">
                <Outlet />
            </Container>
            <FooterPartial />
        </div>
    )

}

export default App