import { Container } from "react-bootstrap";
const FooterPartial = () => {

    return(
        <Container >
            <p><small>Projekt i wykonanie: <strong>Kamil Ślusar</strong> &copy; {new Date().getFullYear()}</small></p>
        </Container>
    )

}
export default FooterPartial;